.blog-page-list-div {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
  /* justify-content: center; */
  transition: all 350ms ease-in;
}

.blog-div {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  min-width: 420px;
  width: 100%;
  transition: all 350ms ease-in;
  box-sizing: border-box;
  cursor: pointer;
}

.blog-image {
  width: 100%;
  max-height: 304px;
  min-height: 304px;
  background-color: #ececec;
  height: auto;
  overflow: hidden;
  border-radius: 16px;
  transition: all 350ms ease-in;
}

.blog-date-info-div {
  display: flex;
  justify-content: space-between;
}

.blog-info-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blog-date-text {
  font-size: 14px;
  line-height: 17.07px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.blog-common-title-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #2d3a66;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 350ms ease-in;
}

.blog-details-main {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff;
}

.display-flex-justify-sb {
  display: flex;
  justify-content: space-between;
}

.common-blog-text,
.blog-render-html-view ol,
.blog-render-html-view p,
.blog-render-html-view h6 {
  font-size: 16px;
  line-height: 19.5px;
  font-family: "Montserrat";
  font-weight: 500 !important;
  color: #34424b !important;
  margin: 0px;
}

.blog-render-html-view ol,
.ql-indent-1m,
.blog-render-html-view p {
  color: #00000080;
  list-style-type: disc;
}

.blog-details-img {
  width: 100%;
  height: auto;
  max-height: 528px;
  background-color: transparent;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
}

.blog-detail-common-img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  object-position: center;
  background-color: #e0e0e0;
}

.blog-render-html-view {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.shimmer-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: transparent;
  overflow: hidden;
  position: relative;
}

.shimmer-image {
  height: 150px;
  background: #e0e0e0;
  border-radius: 16px;
}

.shimmer-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shimmer-date,
.shimmer-title {
  height: 20px;
  background: #e0e0e0;
  border-radius: 5px;
}

.shimmer-title {
  width: 80%;
}

.shimmer-date {
  width: 40%;
}

.shimmer-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

.blog-details-title-text {
  font-size: 36px;
  line-height: 43.88px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
  transition: all 350ms ease-in;
}

.blog-render-html-view h1 {
  font-size: 24px;
  line-height: 29.26px;
  color: #2d3a66;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
}

@keyframes shimmer {
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .blog-common-title-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .blog-date-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .blog-div {
    min-width: 300px;
  }

  .blog-details-main {
    padding: 40px 20px;
  }

  .blog-details-title-text {
    font-size: 32px;
    line-height: 39.01px;
  }

  .blog-details-img {
    max-height: 396px;
  }
}

@media screen and (max-width: 768px) {
  .blog-div {
    min-width: 280px;
  }

  .blog-common-title-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .blog-page-list-div {
    gap: 24px;
  }

  .blog-image {
    max-height: 210px;
    min-height: 210px;
  }

  .blog-details-title-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .blog-details-img {
    max-height: 210px;
  }

  .common-blog-text,
  .blog-render-html-view ol,
  .blog-render-html-view p,
  .blog-render-html-view h6 {
    font-size: 12px;
    line-height: 14.63px;
  }

  .blog-details-main {
    padding: 20px 14px;
    gap: 20px;
  }

  .blog-render-html-view h1 {
    font-size: 14px;
    line-height: 17.07px;
  }
}
