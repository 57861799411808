.main-hero-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  margin-top: 80px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 120px;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: all 350ms ease-in;
}

.hero-title-text {
  font-size: 46px;
  line-height: 56.07px;
  color: var(--blue-2);
  text-align: center;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.hero-desc-text {
  font-size: 20px;
  line-height: 24.38px;
  color: var(--blue-1);
  text-align: center;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 0px;
  transition: all 350ms ease-in;
}

.hero-section-1 {
  max-width: 850px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  transition: all 350ms ease-in;
}

.ipad-img {
  max-width: 552.67px;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
  z-index: 10;
  transition: all 350ms ease-in;
}

.elipse-div {
  position: absolute;
  top: 0px;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .main-hero-div {
    margin-top: 60px;
    gap: 60px;
  }

  .hero-section-1 {
    padding: 0px 20px;
    box-sizing: border-box;
  }

  .hero-title-text {
    font-size: 36px;
    line-height: 43.88px;
    max-width: 700px;
  }

  .hero-desc-text {
    font-size: 16px;
    line-height: 19.5px;
    max-width: 564px;
  }
}

@media screen and (max-width: 768px) {
  .main-hero-div {
    margin-top: 40px;
    gap: 0px;
  }

  .hero-section-1 {
    padding: 0px 14px 40px 14px;
    box-sizing: border-box;
    gap: 20px;
  }

  .hero-title-text {
    font-size: 24px;
    line-height: 29.26px;
  }

  .hero-desc-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .ipad-img {
    max-width: none;
    width: 100%;
    padding: 0px 34px;
    box-sizing: border-box;
  }
}
