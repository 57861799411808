.nav-main-div {
  width: 100%;
  background-color: var(--white);
  position: sticky;
  top: 0px;
  z-index: 99;
}

.nav-container {
  width: 100%;
  max-width: 1440px;
  padding: 20px 80px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.logo-text {
  font-size: 16px;
  line-height: 19px;
  font-family: "Montserrat";
  font-weight: 700;
  color: var(--blue-1);
  margin: 0px;
  transition: all 350ms ease-in;
}

.nav-logo-div {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 350ms ease-in;
}

.nav-link {
  font-size: 14px;
  line-height: 17.64px;
  color: var(--gray-5);
  font-family: "Montserrat";
  font-weight: 600;
  text-decoration: none;
  margin: 0px;
  transition: color 300ms ease-in-out;
}

.nav-link:hover {
  color: var(--blue-3);
}

.active-link {
  color: var(--blue-3);
}

.nav-link-div {
  display: flex;
  gap: 50px;
}

.nav-btn-div,
.hero-section-btn-div {
  display: flex;
  gap: 10px;
}

.login-btn {
  border: 1px solid var(--blue-2);
  padding: 10px 20px;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  line-height: 17.64px;
  color: var(--blue-2);
  border-radius: 8px;
  max-height: 38px;
  font-family: "Montserrat";
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  transition: border 350ms ease-in-out;
  z-index: 10;
}

.login-btn:hover {
  border: 1px dashed var(--blue-2);
}

.common-btn {
  border: none;
  padding: 10px 20px;
  background-color: var(--blue-2);
  outline: none;
  font-size: 14px;
  line-height: 17.64px;
  color: var(--white);
  border-radius: 8px;
  max-height: 38px;
  font-family: "Montserrat";
  font-weight: 600;
  box-sizing: border-box;
  box-shadow: none;
  transition: all 350ms ease-in-out;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-btn:hover {
  box-shadow: 0px 4px 10px 0px #0000002e;
}

.menu-icon {
  width: 24px;
  height: 24px;
  z-index: 1001;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  transition: all 350ms ease-in;
}

.menu-icon-display {
  display: none;
}

.menu-icon span {
  height: 2px;
  background-color: #000000;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.menu-icon span:nth-child(1) {
  width: 60%;
  align-self: flex-end;
}
.menu-icon span:nth-child(2) {
  width: 100%;
}

.menu-icon span:nth-child(3) {
  width: 60%;
  align-self: flex-end;
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 4px);
  width: 80%;
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
  width: 80%;
}

.popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  transition: all 0.5s ease;
}

.popup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1002;
  margin-top: 72px;
  margin-right: 0px;
  padding: 0px 20px 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  align-self: flex-start;
  transition: all 0.5s ease;
  gap: 24px;
  transition: all 350ms ease-in;
}

.popup-overlay {
  position: fixed;
  top: 84px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  /* backdrop-filter: blur(12px); */
}

.display-flex-10 {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .menu-icon-display {
    display: flex;
    flex-direction: column;
  }

  .nav-btn-div {
    display: none;
  }

  .nav-link-div {
    display: none;
  }

  .nav-container {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .nav-container {
    padding: 14px;
  }

  .popup {
    margin-top: 60px;
  }
}
