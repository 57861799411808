.about-main-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  background-color: transparent;
  padding: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  overflow: hidden;
  z-index: 10;
  background-color: #fff;
  transition: all 350ms ease-in;
}

.about-info-div {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  transition: all 350ms ease-in;
}

.common-label {
  font-size: 14px;
  line-height: 17.07px;
  font-family: "Montserrat";
  font-weight: 500;
  color: var(--blue-4);
  padding: 8px 18px;
  border-radius: 100px;
  max-height: 33px;
  border: 1px solid var(--blue-4);
  transition: all 350ms ease-in;
}

.about-desc-text {
  font-size: 36px;
  line-height: 43.88px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
  transition: all 350ms ease-in;
}

.about-image-div {
  max-width: 524px;
  width: 100%;
  height: 320px;
  border-radius: 16px;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.common-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  background-color: #e0e0e0;
  transition: all 350ms ease-in;
}

.about-more-info-section {
  display: flex;
  gap: 60px;
  align-items: center;
  transition: all 350ms ease-in;
}

.about-more-info-text {
  font-size: 26px;
  line-height: 34.84px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #2d3a66;
  margin: 0px;
  transition: all 350ms ease-in;
}

.about-more-info-div {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  transition: all 350ms ease-in;
}

.benefit-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.benefit-desc-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #2d3a66;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  text-align: center;
  transition: all 350ms ease-in;
}

.display-column-gap-8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 350ms ease-in;
}

.benefit-info-div {
  flex: 1;
  border: 1px solid #34424b;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  box-shadow: none;
  border-radius: 16px;
  transition: all 300ms ease-in-out;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.benefit-info-div:hover {
  box-shadow: 0px 0px 26px 0px #0000001a;
  border: 1px solid transparent;
}

.benefit-list-div {
  display: flex;
  gap: 16px;
  transition: all 350ms ease-in;
}

.benefit-card-title-text {
  font-size: 24px;
  line-height: 29.26px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  text-align: center;
  transition: all 350ms ease-in;
}

.benefit-card-desc-text,
.how-work-card-desc-text {
  font-size: 16px;
  line-height: 19.5px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #34424bb2;
  text-align: center;
  margin: 0px;
  transition: all 350ms ease-in;
}

.service-main-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  overflow: hidden;
}

.service-img-div {
  max-width: 480px;
  width: 100%;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.service-card {
  display: flex;
  gap: 52px;
  transition: all 350ms ease-in;
}

.service-card:nth-child(2n) {
  flex-direction: row-reverse;
}

.service-card-info-div {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  padding: 32px 0px;
  justify-content: center;
  transition: all 350ms ease-in;
}

.service-card-title-text {
  font-size: 32px;
  line-height: 39.01px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.service-card-desc-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #828282;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.service-card-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 350ms ease-in;
}

.how-work-main-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.how-work-step-div {
  display: flex;
  gap: 16px;
  transition: all 350ms ease-in;
}

.how-work-card {
  flex: 1;
  padding: 52px 20px 20px 20px;
  box-shadow: 0px 0px 26px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  border-radius: 16px;
  position: relative;
  transition: all 350ms ease-in;
}

.steps-label {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232e51;
  top: -30px;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #ffffff;
  transition: all 350ms ease-in;
}

.price-section-container {
  width: 100%;
  background-color: #232e51;
  transition: all 350ms ease-in;
}

.price-section-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  display: flex;
  gap: 60px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.price-section-1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 515px;
  align-items: flex-start;
  transition: all 350ms ease-in;
}

.pricing-label {
  font-size: 14px;
  line-height: 17.07px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #d4d4d4;
  padding: 8px 18px;
  border-radius: 100px;
  max-height: 33px;
  border: 1px solid #d4d4d4;
  transition: all 350ms ease-in;
}

.pricing-desc-text {
  font-size: 36px;
  line-height: 43.88px;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  text-transform: capitalize;
  transition: all 350ms ease-in;
}

.plan-price-card {
  min-width: 340px;
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  gap: 32px;
  transition: all 350ms ease-in;
}

.plan-price-card:first-child {
  background-color: #ececec;
}

.plan-fetaure-div {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 350ms ease-in;
}

.plan-features-list,
div ol {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px;
  transition: all 350ms ease-in;
}

.plan-features-text {
  font-size: 14px;
  line-height: 17.07px;
  color: #34424bb8;
  font-weight: "Montserrat";
  font-weight: 400;
  margin: 0px;
  transition: all 350ms ease-in;
}

.plan-name-text {
  font-size: 28px;
  line-height: 34.13px;
  color: #34424b;
  font-weight: "Montserrat";
  font-weight: 600;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  transition: all 350ms ease-in;
}

.display-column-gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 350ms ease-in;
}

.pricing-plan-div {
  display: flex;
  gap: 20px;
  transition: all 350ms ease-in;
}

.plan-price-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  transition: all 350ms ease-in;
}

.price-plan-text {
  font-size: 24px;
  line-height: 29.26px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #34424b;
  margin: 0px;
  transition: all 350ms ease-in;
}

.package-text {
  font-size: 16px;
  line-height: 19.5px;
  transition: all 350ms ease-in;
}

.display-column-gap-6 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  transition: all 350ms ease-in;
}

.testimonial-div {
  width: 100%;
  /* max-width: 1440px; */
  padding: 80px 0px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.testimonial-card {
  min-width: 520px;
  padding: 16px 48px 16px 16px;
  background: linear-gradient(
    223.38deg,
    rgba(45, 58, 102, 0) -33.77%,
    rgba(45, 58, 102, 0.1) 98.46%
  );
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 28px;
  transition: all 350ms ease-in;
}

.user-country-div {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  line-height: 19.5px;
  color: #2d3a66;
  font-family: "Montserrat";
  font-weight: 500;
  text-transform: capitalize;
  transition: all 350ms ease-in;
}

.comment-text {
  font-size: 18px;
  line-height: 21.94px;
  color: #34424bb8;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.user-image-div {
  width: 72px;
  height: 72px;
  border-radius: 100px;
  background-color: #fff;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.username-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #2d3a66;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.user-info-div {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 350ms ease-in;
}

.testimonial-card-div {
  display: flex;
  gap: 16px;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
  scroll-behavior: smooth;
  padding: 0px 16px;
  transition: all 350ms ease-in;
}

.testimonial-card-div.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.testimonial-card-div::-webkit-scrollbar {
  display: none;
}

.arrow-btn {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: #2d3a661a;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 350ms ease-in;
}

.testimonial-slider-div {
  display: flex;
  gap: 200px;
  justify-content: center;
  transition: all 350ms ease-in;
}

.margin-left-80 {
  margin-left: 80px;
}

.margin-right-80 {
  margin-right: 80px;
}

.blog-section-div {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.blogs-container {
  display: flex;
  gap: 16px;
  /* justify-content: center; */
  width: 100%;
  transition: all 350ms ease-in;
}

.blog-card {
  flex: 1;
  max-width: 416px;
  width: 100%;
  height: 300px;
  background-color: #00000033;
  box-shadow: 0px 0px 28px 0px #00000024;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 350ms ease-in;
}

.blog-overlay {
  position: absolute;
  background-color: #00000033;
  width: 100%;
  height: 100%;
  top: 0px;
}

.blog-date-text {
  font-size: 14px;
  line-height: 17.07px;
  color: #b3b1b1;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 0px;
  transition: all 350ms ease-in;
}

.display-flex-justify-sb {
  display: flex;
  justify-content: space-between;
  transition: all 350ms ease-in;
}

.blog-title-text {
  font-size: 16px;
  line-height: 19.5px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.blog-card-info {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  background-color: #fff;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  transition: all 350ms ease-in;
}

.faq-section {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 80px;
  background-color: #fff;
  display: flex;
  gap: 42px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 350ms ease-in;
}

.faq-section-1 {
  max-width: 487px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  transition: all 350ms ease-in;
}

.faq-section-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  transition: all 350ms ease-in;
}

.accordiation-card {
  width: 100%;
  border: 0.8px solid #232e51;
  padding: 20px 20px 20px 24px;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.faq-qestion-text {
  font-size: 16px;
  color: #232e51;
  letter-spacing: 2%;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.answer-text {
  font-size: 16px;
  color: #2d3a66;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 0px;
  transition: all 350ms ease-in;
}

.dropdown-icon {
  rotate: 180deg;
  transition: all 230ms ease-in-out;
}

.dropdown-icon-1 {
  rotate: 0deg;
  transition: all 230ms ease-in-out;
}

.display-none {
  display: none;
}

.slick-center .carousel-item {
  margin-top: 0px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center !important;
  height: auto;
  transition: transform 0.5s ease;
  column-gap: 50px;
  outline: none;
}

@media screen and (max-width: 1024px) {
  .about-main-div,
  .benefit-div,
  .service-main-div,
  .how-work-main-div,
  .price-section-div,
  .faq-section,
  .blog-section-div {
    padding: 40px 20px;
  }

  .about-desc-text {
    font-size: 28px;
    line-height: 34.13px;
  }

  .about-image-div {
    flex: 1;
    height: auto;
    max-height: 242px;
  }

  .about-more-info-section {
    gap: 16px;
  }

  .about-more-info-div {
    justify-content: center;
    flex: 1;
  }

  .about-more-info-text {
    font-size: 16px;
    line-height: 21.44px;
  }

  .benefit-desc-text,
  .service-card-desc-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .benefit-list-div {
    gap: 12px;
  }

  .benefit-card-title-text {
    font-size: 20px;
    line-height: 24.38px;
  }

  .service-card-info-div {
    flex: 1;
    gap: 24px;
  }

  .service-img-div {
    height: auto;
    max-height: 242px;
    flex: 1;
  }

  .service-card-title-text {
    font-size: 24px;
    line-height: 29.26px;
  }

  .how-work-main-div {
    gap: 60px;
  }

  .how-work-card-desc-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .price-section-div {
    flex-direction: column;
  }

  .price-section-1 {
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: none;
  }

  .pricing-desc-text {
    font-size: 28px;
    line-height: 34.13px;
    text-align: center;
  }

  .pricing-plan-div {
    gap: 16px;
    justify-content: center;
  }

  .plan-price-card {
    min-width: 100px;
    max-width: 340px;
    width: 100%;
    flex: 1;
    /* max-height: 380px; */
  }

  .plan-name-text {
    font-size: 24px;
    line-height: 29.26px;
  }

  .testimonial-div {
    padding: 40px 0px;
  }

  .margin-left-80 {
    margin-left: 20px;
  }

  .margin-right-80 {
    margin-right: 20px;
  }

  .testimonial-slider-div {
    gap: 20px;
  }

  .faq-section {
    flex-direction: column;
    gap: 40px;
  }

  .faq-section-1 {
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: none;
  }

  .blogs-container {
    gap: 12px;
  }

  .blog-section-div {
    gap: 40px;
  }

  .blog-title-text {
    font-size: 14px;
    line-height: 17.07px;
  }

  .blog-date-text {
    font-size: 12px;
    line-height: 14.63px;
  }
}

@media screen and (max-width: 768px) {
  .about-main-div,
  .benefit-div,
  .service-main-div,
  .how-work-main-div,
  .price-section-div,
  .faq-section,
  .blog-section-div {
    padding: 20px 14px;
  }

  .about-desc-text,
  .username-text,
  .pricing-desc-text,
  .plan-name-text,
  .benefit-card-title-text,
  .service-card-title-text,
  .benefit-card-title-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .common-label {
    font-size: 12px;
    line-height: 14.63px;
    max-height: 31px;
  }

  .accordiation-card {
    padding: 12px;
  }

  .faq-qestion-text,
  .answer-text,
  .blog-title-text,
  .comment-text,
  .plan-features-text,
  .package-text,
  .service-card-desc-text,
  .benefit-desc-text,
  .benefit-card-desc-text,
  .about-more-info-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .blogs-container {
    flex-direction: column;
    gap: 12px;
  }

  .blog-card {
    max-width: none;
    min-height: 210px;
    max-height: 210px;
  }

  .blog-card-info {
    gap: 12px;
  }

  .arrow-btn {
    width: 36px;
    height: 36px;
  }

  .testimonial-div {
    padding: 20px 14px;
  }

  .margin-right-80 {
    margin-right: 0px;
  }

  .margin-left-80 {
    margin-left: 0px;
  }

  .testimonial-card {
    min-width: 100%;
    padding: 16px 14px;
    gap: 12px;
    box-sizing: border-box;
  }

  .user-country-div {
    font-size: 12px;
  }

  .global-icon {
    width: 20px;
    height: 20px;
  }

  .user-image-div {
    width: 52px;
    height: 52px;
  }

  .price-section-div,
  .benefit-div,
  .about-main-div {
    gap: 20px;
  }

  .plan-price-card {
    padding: 12px;
    gap: 24px;
    border-radius: 16px;
  }

  .price-plan-text {
    font-size: 20px;
    line-height: 24.83px;
  }

  .how-work-main-div {
    gap: 26px;
  }

  .how-work-step-div {
    flex-direction: column;
    gap: 24px;
  }

  .steps-label {
    width: 32px;
    height: 32px;
    top: -16px;
    font-size: 12px;
    /* line-height: 14.63px; */
  }

  .how-work-card {
    padding: 22px 12px;
    gap: 12px;
    border-radius: 14px;
  }

  .service-main-div {
    gap: 20px;
  }

  .benefit-info-div {
    gap: 16px;
  }

  .benefit-list-div {
    gap: 12px;
  }

  .about-more-info-div {
    gap: 20px;
  }

  .about-image-div {
    max-height: 180px;
    max-width: none;
  }
}

@media screen and (max-width: 567px) {
  .pricing-plan-div {
    flex-direction: column;
    gap: 16px;
  }

  .plan-price-card {
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }

  .service-card {
    flex-direction: column;
    gap: 12px;
  }

  .service-card:nth-child(2n) {
    flex-direction: column;
    gap: 12px;
  }

  .service-card-info-div {
    padding: 0px;
    gap: 12px;
  }

  .service-img-div {
    max-width: none;
    max-height: 150px;
  }

  .mobile-text-center {
    text-align: center !important;
    width: 100%;
  }

  .mobile-display-none {
    display: none;
  }

  .display-none {
    display: block;
  }

  .benefit-list-div {
    flex-direction: column;
  }

  .about-more-info-section {
    flex-direction: column;
    gap: 20px;
  }

  .mobile-align-self-center {
    align-self: center;
  }
}
