@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --white: #ffffff;
  --white-light: #ffffffa3;
  --blue-1: #34424b;
  --blue-2: #232e51;
  --blue-3: #2d3a66;
  --blue-4: #1d4e93;
  --blue-1-light: #34424bb2;
  --gray-1: #828282;
  --gray-2: #d4d4d4;
  --gray-3: #ececec;
  --gray-4: #b3b1b1;
  --gray-5: #4f4f4f;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* scroll-padding: 0px;
  scrollbar-width: 0px;
  overflow: auto; */
}

/* body::-webkit-scrollbar {
  display: none;
} */
