.main-content {
  position: relative;
}

.main-container {
  background-color: var(--white);
  width: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
