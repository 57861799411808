.contact-main-container {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  gap: 100px;
  padding: 52px 80px;
  transition: all 350ms ease-in;
}

.contact-header-text {
  font-size: 36px;
  line-height: 43.88px;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.contact-desc-text {
  font-size: 16px;
  line-height: 19.5px;
  color: #000000b2;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 0px;
  text-decoration: none;
  transition: all 350ms ease-in;
}

.context-common-label-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.contact-flex-column-28 {
  display: flex;
  flex-direction: column;
  gap: 28px;
  transition: all 350ms ease-in;
}

.contact-us-section-1 {
  display: flex;
  flex-direction: column;
  gap: 52px;
  transition: all 350ms ease-in;
}

.contact-flex-column-16 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 350ms ease-in;
}

.contact-flex-column-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 350ms ease-in;
}

.contact-flex-20 {
  display: flex;
  gap: 20px;
}

.contact-us-section-2 {
  min-width: 439px;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 17.07px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #1d4e93;
  transition: all 350ms ease-in;
}

.contact-form input,
.contact-form textarea {
  padding: 11px 12px;
  margin-top: 8px;
  border: none;
  border-radius: 8px;
  background-color: #2d3a6608;
  color: #34424b;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 500;
  outline: none;
  transition: all 350ms ease-in;
  resize: none;
}

.contact-form textarea {
  height: 84px;
  transition: all 350ms ease-in;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #34424b80;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
  font-family: "Montserrat";
  font-weight: 500;
}

.display-none-mobile-visible {
  display: none;
  transition: all 350ms ease-in;
}

.social-link {
  transition: all 0.5s ease;
}

.social-link:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
  .contact-main-container {
    padding: 40px 20px;
    gap: 40px;
  }

  .contact-header-text {
    font-size: 24px;
    line-height: 29.26px;
  }

  .contact-desc-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .contact-flex-column-10 {
    gap: 4px;
  }

  .context-common-label-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .contact-flex-20 {
    margin-top: 12px;
  }

  .contact-us-section-2 {
    flex: 1;
    min-width: 381px;
  }
}

@media screen and (max-width: 768px) {
  .contact-main-container {
    padding: 20px 14px;
    flex-direction: column;
    gap: 60px;
  }

  .contact-header-text {
    font-size: 20px;
    line-height: 24.38px;
    text-align: center;
  }

  .contact-us-section-1 {
    gap: 40px;
  }

  .context-common-label-text {
    font-size: 14px;
    line-height: 17.07px;
  }

  .contact-flex-20 {
    margin-top: 4px;
  }

  .contact-us-section-2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .display-none-mobile-visible {
    display: block;
  }

  .contact-form {
    gap: 16px;
    padding: 0px;
  }

  .contact-us-section-2 {
    min-width: auto;
  }
}
