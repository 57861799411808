.service-container {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 40px 80px;
  background-color: #ffffff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
  transition: all 350ms ease-in;
  flex: 1;
}

.service-list-div {
  display: flex;
  flex-direction: column;
  gap: 80px;
  transition: all 350ms ease-in;
}

.service-main-card {
  display: flex;
  gap: 80px;
  transition: all 350ms ease-in;
}

.service-main-card:nth-child(2n) {
  flex-direction: row-reverse;
}

.service-page-img-div {
  flex: 1;
  border-radius: 16px;
  overflow: hidden;
  /* max-height: 600px; */
  transition: all 350ms ease-in;
}

.services-info-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  transition: all 350ms ease-in;
}

.more-info-service {
  display: flex;
  gap: 10px;
  padding: 8px 10px;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 19.5px;
  font-family: "Montserrat";
  font-weight: 500;
  transition: all 350ms ease-in;
}

.service-title-text {
  font-size: 32px;
  line-height: 32px;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.service-description-text {
  font-size: 16px;
  line-height: 19.5px;
  color: #000000b2;
  font-family: "Montserrat";
  font-weight: 400;
  margin: 0px;
  transition: all 350ms ease-in;
}

.include-text {
  font-size: 20px;
  line-height: 14.38px;
  color: #000000b2;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  transition: all 350ms ease-in;
}

.more-info-div-service {
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 350ms ease-in;
}

.service-header-text {
  font-size: 36px;
  line-height: 43.88px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
  transition: all 350ms ease-in;
}

.responsive-service-img-mobile {
  display: none;
  transition: all 350ms ease-in;
}

@media screen and (max-width: 1024px) {
  .service-container {
    padding: 20px;
    gap: 40px;
  }

  .service-list-div {
    gap: 40px;
  }

  .service-main-card {
    gap: 16px;
  }

  .service-page-img-div {
    max-height: 450px;
  }

  .service-title-text {
    font-size: 28px;
    line-height: 28px;
  }

  .service-description-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .services-info-div {
    gap: 20px;
  }

  .include-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .more-info-service {
    font-size: 12px;
    line-height: 14.63px;
  }
}

@media screen and (max-width: 768px) {
  .service-container {
    padding: 20px 14px;
  }
  .service-header-text {
    font-size: 20px;
    line-height: 24.38px;
  }

  .service-title-text {
    font-size: 16px;
    line-height: 16px;
  }

  .more-info-service {
    padding: 8px 0px;
  }

  .service-main-card {
    gap: 20px;
  }

  .service-page-img-div {
    display: none;
  }

  .responsive-service-img-mobile {
    display: block;
    height: auto;
    border-radius: 14px;
    width: 100%;
    overflow: hidden;
    max-height: 200px;
  }
}
