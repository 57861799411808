.footer-main-div {
  width: 100%;
  background-color: #232e51;
}

.footer-container {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 60px 80px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 350ms ease-in;
}

.divider-line {
  width: 100%;
  height: 1px;
  background-color: #ffffff80;
  transition: all 350ms ease-in;
}

.footer-common-text {
  font-size: 14px;
  line-height: 17.07px;
  color: #ffffffa3;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  text-decoration: none;
  transition: all 350ms ease-in;
}

.footer-logo-text {
  font-size: 20px;
  line-height: 24.38px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 700;
  margin: 0px;
  text-transform: uppercase;
  transition: all 350ms ease-in;
}

.display-flex-center-10 {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 350ms ease-in;
}

.footer-section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 350ms ease-in;
}

.display-flex-12 {
  display: flex;
  gap: 12px;
}

.footer-section-1 {
  display: flex;
  gap: 100px;
  transition: all 350ms ease-in;
}

.flex-1 {
  flex: 1;
}

.flex-column-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-highlight-text {
  font-size: 16px;
  line-height: 19.5px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  margin: 0px;
  transition: all 350ms ease-in;
}

.footer-link-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex-column-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 350ms ease-in;
}

.footer-social-div {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  transition: all 350ms ease-in;
}

.footer-email-text-input {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 500;
  border: none;
  outline: none;
  flex: 1;
  padding: 0px;
  background-color: transparent;
  transition: all 350ms ease-in;
}

.footer-email-text-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.subscribe-input-div {
  width: 100%;
  padding-left: 14px;
  border: 1px solid #fff;
  border-radius: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  box-sizing: border-box !important;
  transition: all 350ms ease-in;
}

.subscribe-btn {
  font-size: 14px;
  line-height: 17.64px;
  color: #34424b;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 10px 24px;
  border-radius: 200px;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
  min-width: 120px;
  transition: all 350ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-14 {
  display: flex;
  flex-direction: column;
  gap: 14px;
  transition: all 350ms ease-in;
}

.modalBoxWaitlist {
  width: 800px;
  max-width: 90%;
  height: auto;
  max-height: 95%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 30px 60px 0px 60px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
}

.term-modal-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.agreementTitle {
  font-size: 18px;
  font-weight: 600;
  color: #34424b;
  align-self: center;
}

.agreementTxt {
  font-weight: 500;
  font-size: 16px;
  color: rgba(45, 58, 102, 1);
  line-height: 24px;
  letter-spacing: 2%;
  text-align: left;
  padding: 20px 0px 20px 0px;
}
.agreementTxt1 {
  font-weight: 500;
  font-size: 16px;
  color: rgba(45, 58, 102, 1);
  line-height: 24px;
  letter-spacing: 2%;
  flex-wrap: wrap;
  text-align: left;
}
.agreementBold {
  font-size: 16px;
  font-weight: 700;
}
.lineHeight {
  line-height: 30px;
}
.listTxt {
  font-weight: 500;
  font-size: 16px;
  color: rgba(45, 58, 102, 1);
  line-height: 24px;
  letter-spacing: 2%;
  text-align: left;
  margin: 0px 0px 16px 10px;
}

.listTxtDec {
  font-weight: 500;
  font-size: 16px;
  color: rgba(45, 58, 102, 1);
  line-height: 24px;
  letter-spacing: 2%;
  text-align: left;
}

.list-style {
  color: #34424b;
}

ol {
  /* font-size: 8px;
  font-weight: 700; */
}
.listTxtInner {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  list-style-type: lower-alpha;
  margin: 0 0 0 10px;
}
.listTxtInner1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 10px;
  list-style-type: circle;
}

.termListType {
  list-style-type: decimal;
}

.privacyDesc {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

ol > li::marker {
  font-weight: bold;
}

.privacyInnerList {
  list-style-type: none;
  font-weight: 500;
  font-size: 16px;
  color: rgba(45, 58, 102, 1);
  line-height: 24px;
  letter-spacing: 2%;
  text-align: left;
  margin: 10px;
}

.setTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  letter-spacing: 2%;
  color: rgba(52, 66, 75, 1);
  padding: 10px 0px 10px 0px;
  text-transform: uppercase;
  margin: 0px;
}

.term-modal-title {
  border-bottom-width: 3px;
  border-style: solid;
  border-color: rgba(212, 173, 45, 1);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.img-div {
  justify-content: flex-end;
  display: flex;
}

.containerTerm {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  margin: 30px 20px;
}

@media screen and (max-width: 1024px) {
  .footer-container {
    padding: 40px 20px;
  }

  .footer-highlight-text {
    font-size: 14px;
    line-height: 17.07px;
  }

  .footer-common-text {
    font-size: 12px;
    line-height: 14.63px;
  }

  .footer-logo-text {
    font-size: 16px;
    line-height: 19.5px;
  }

  .modalBoxWaitlist {
    width: 100%;
    max-width: 90%;
    max-height: 95%;
  }
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 20px 14px;
    gap: 32px;
  }

  .modalBoxWaitlist {
    padding: 20px 40px 0px 40px;
  }

  .footer-section-2 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-section-1 {
    flex-wrap: wrap;
    gap: 32px;
  }

  .flex-column-sb {
    gap: 32px;
  }

  .footer-social-div {
    justify-content: flex-start;
  }

  .order-3 {
    order: 3;
  }

  .order-2 {
    order: 2;
  }
  .agreementTitle {
    font-size: 16px;
  }

  .agreementTxt,
  .agreementTxt1 {
    font-size: 14px;
    line-height: 22px;
  }

  .agreementTitle {
    font-size: 16px;
  }

  .agreementBold {
    font-size: 14px;
  }

  .containerTerm {
    margin: 20px 10px;
  }

  .listTxt {
    margin: 0px 0px 15px 16px;
    font-size: 14px;
    line-height: 22px;
  }
  .setTitle {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 480px) {
  .modalBoxWaitlist {
    width: 100%;
    padding: 15px 25px 0px 25px;
  }
  .agreementTitle {
    font-size: 14px;
  }

  .agreementTxt,
  .agreementTxt1 {
    font-size: 12px;
    line-height: 20px;
  }

  .agreementTitle {
    font-size: 14px;
  }

  .listTxtInner1,
  .agreementBold,
  .listTxtInner {
    font-size: 12px;
    line-height: 20px;
  }

  .listTxt {
    font-size: 12px;
    line-height: 20px;
    margin: 0px 0px 10px 12px;
  }

  .listTxtDec {
    font-size: 12px;
    line-height: 20px;
  }
  .setTitle {
    font-size: 14px;
    line-height: 16px;
  }
}
